@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,900&display=swap");

body {
  font-family: "Nunito", sans-serif !important;
}

.btn-no-outline:focus {
  outline: none !important;
  outline-offset: none !important;
}

.slick-bottom-track > img {
  /* max-width: 100%; */
  height: 40px;
}

.slick-bottom-track {
  margin-left: 10px;
  margin-right: 10px;
}

.slick-thumb {
  height: 40px;
  padding-top: 50px;
}

.slick-thumb > li {
  width: 100px !important;
  height: 50px !important;
}

.slick-list {
  margin-bottom: 100px;
}
